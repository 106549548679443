<template>
  <div class="chat-layout">
    <div class="chat-overlay" ref="chatOverlay" :style="{
      'height': height
    }">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ChatLayout",
  data: () => {
    return {
      height: '100vh',
    }
  },
  computed: {
    ...mapState([
      'navbarMargin',
    ]),
  },
  created() {
    this.height = window.innerHeight + 'px!important'
    let body = document.querySelector('body')
    body.classList.add('has-chat')
  },
  beforeDestroy() {
    let body = document.querySelector('body')
    body.classList.remove('has-chat')
  },
};
</script>

<style lang="scss">
@import url("https://rsms.me/inter/inter.css");

.chat-overlay {
  width: 100vw;
  text-size-adjust: 100%;
  font-size: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    height: calc(100vh - 50px) !important;
    overflow-y: hidden !important;
  }

  a {
    background: 0 0;
  }

  img {
    object-fit: cover;
    border: 1px solid rgba(0,0,0,.08);
    max-width: 100%;
    display: inherit;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    outline: 0;
    border: 0;
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Inter, sans-serif;
    font-stretch: normal;
    font-weight: 400;
  }

  h4,
  .font-22 {
    font-size: 2.2rem;
    line-height: 27px;
  }

  .font-15 {
    font-size: 1.5rem;
    line-height: 20px;
  }

  a {
    text-decoration: none;
  }

  a {
    background-color: transparent;
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  a {
    color: inherit;
  }

  .chat__list {
    table {
      td, th {
        border: 1px solid rgba(50, 50, 50, 0.4);
        padding: .5rem .75rem;
      }
    }
  }
}
</style>

